import React, { createContext, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import commonApi from '../utils/api';
import { CommonContext } from './CommonContext';

export const PricingContext = createContext();

export function PricingContextProvider({ children }) {
    const { user, courierData } = useContext(CommonContext)
    const location = useLocation();
    const [addModal, setAddModal] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [viewData, setViewData] = useState('');
    const [allData, setAllData] = useState([]);
    const [allMerchant, setAllMerchant] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMesaage, setAlertMesaage] = useState('');
    const [severity, setSeverity] = useState('');
    const [zoneConstants, setZonesConstants] = useState({});
    const [charges, setCharges] = useState([]);
    const [pricingCourier, setPricingCourier] = useState([]);
    const [zoneForm, setZoneForm] = useState({});
    const [pricingForm, setPricingForm] = useState({
        courier_id: '',
    });
    const [locationState, setLocationState] = useState({});

    console.log('pricingForm12', pricingForm)

    const onCloseAlert = () => {
        setOpenAlert(false);
        setAlertMesaage('');
        setSeverity('')
    };
    const handleAddModals = () => {
        setAddModal(true);
    };


    const handleCloseModal = () => {
        setViewData('');
        // setCharges([]);
        setIsEdit(false);
        setAddModal(false);
        setPricingForm({
            courier_id: '',
        });
    };

    const onPriceConstants = () => {
        setIsLoading(true);
        commonApi.post('/merchant/price-constants')
            .then((response) => {
                setIsLoading(false);
                if (response?.data?.data) {
                    setZonesConstants(response?.data?.data.zones);
                    setCharges(response?.data?.data.other_charges);
                    setZoneForm(Object.fromEntries(Object.keys(response?.data?.data.zones).map((key) => [key, ''])));
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    };

    const onGetPrice = (id) => {
        setIsLoading(true);
        setIsEdit(true);
        const postReq = { merchant_id: locationState?.id, admin_id: user?.id, courier_id: id }
        commonApi.post('/merchant/get-price', postReq)
            .then((response) => {
                setIsLoading(false);
                if (response?.data?.s === 1) {
                    setViewData(response?.data?.data);
                    if (Number(response?.data?.service_type) === 1) {
                        setPricingForm((prevData) => ({
                            ...response?.data?.data,
                            service_type: response?.data?.service_type,
                        }));
                    } else {
                        setPricingForm((prevData) => ({
                            ...prevData,
                            service_type: response?.data?.service_type,
                            courier_id: response?.data?.data?.courier_id,
                        }));
                        setZoneForm(response?.data?.data.zones);
                        setCharges(response?.data?.data.other_charges);
                    }
                    courierData(response?.data?.service_type)
                    handleAddModals();
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    };
    const onGetCourier = (id) => {
        setFirstLoading(false);
        const postReq = { merchant_id: id, admin_id: user?.id, }
        commonApi.post('/merchant/get-my-couriers', postReq)
            .then((response) => {
                setFirstLoading(true);
                if (response?.data?.data) {
                    setPricingCourier(response?.data?.data);
                }
            })
            .catch((error) => {
                setFirstLoading(true);
                console.error('Error fetching data:', error);
            });
    };

    const onLoadApi = () => {
        setFirstLoading(false);
        commonApi.post('/merchant/list')
            .then((response) => {
                setFirstLoading(true);
                if (response?.data?.data) {
                    setAllMerchant(response?.data?.data);
                    setAllData(response?.data?.data);
                }
            })
            .catch((error) => {
                setFirstLoading(true);
                console.error('Error fetching data:', error);
            });
    };


    useEffect(() => {
        if (location?.state) {
            onGetCourier(location.state?.id);
            setLocationState(location.state)
        }
        onPriceConstants();
    }, [location])

    // You can provide any values or functions you want to make available to consumers here
    const contextValue = {
        onPriceConstants,
        setZoneForm,
        zoneForm,
        onGetCourier,
        pricingCourier,
        locationState,
        onGetPrice,
        pricingForm,
        charges,
        zoneConstants,
        onCloseAlert,
        severity,
        alertMesaage,
        openAlert,
        isEdit,
        onLoadApi,
        isLoading,
        firstLoading,
        viewData,
        allData,
        allMerchant,
        handleAddModals,
        handleCloseModal,
        setAddModal,
        addModal,
        setAllMerchant,
        setAllData,
        setViewData,
        setFirstLoading,
        setIsLoading,
        setIsEdit,
        setSeverity,
        setAlertMesaage,
        setOpenAlert,
        setZonesConstants,
        setCharges,
        setPricingForm,
        setLocationState,
        setPricingCourier
    };

    // Render the provider with children
    return <PricingContext.Provider value={contextValue}>{children}</PricingContext.Provider>;
}
