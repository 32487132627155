import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utils/api';
import { CommonContext } from './CommonContext';
import { useNavigate } from 'react-router-dom';

export const IVRContext = createContext();

export function IVRContextProvider({ children }) {
    const { user } = useContext(CommonContext);
    const navigate = useNavigate();
    const [addModal, setAddModal] = useState(false);
    const [allData, setAllData] = useState([]);
    const [allRecordings, setAllRecordings] = useState([]);
    const [viewData, setViewData] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [alertMesaage, setAlertMesaage] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [severity, setSeverity] = useState('');
    const [recordingurl, setRecordingurl] = useState(null);
    const [fDateRange, setFDateRange] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [filterInput, setFilterInput] = useState({
        from_mobile: '',
        to_mobile: '',
        start_date: '',
        end_date: '',
    });
    const [formTemp, setFormTemp] = useState({
        main_remarks: "",
        sub_remarks: "",
        follow_date: null,
        remarks: "",
    });
    const [openSmodal, setOpenSmodal] = useState(false);

    const handleOpenModal = (row) => {
        setOpenSmodal(true);
        setFormTemp({
            ...formTemp,
            id: row?.id,
        })
    };

    const handleCloseModal = () => {
        setOpenSmodal(false);
        setFormTemp({
            main_remarks: "",
            sub_remarks: "",
            follow_date: null,
            remarks: "",
        });
    };

    const onLoadApi = (pagePrPage) => {
        console.log('pagePrPage', pagePrPage)
        const url = `/ivr/list?page=${pagePrPage?.page ?? 1}&per_page=${pagePrPage?.rowsPerPage ?? 20}`;
        if (user?.user_type === "Merchant") {
            filterInput.merchant_id = user?.id
        } else {
            filterInput.admin_id = user?.id
        }
        setFirstLoading(false);
        commonApi.post(url, filterInput)
            .then((response) => {
                setFirstLoading(true);
                if (response?.data?.data) {
                    setAllRecordings(response?.data?.data);
                    setAllData(response?.data);
                    setRecordingurl(response?.data?.recording_base_url);
                }
            })
            .catch((error) => {
                setFirstLoading(true);
                console.error('Error fetching data:', error);
            });
    };
    const onExport = () => {
        setIsLoading(true);
        commonApi.post('/ivr/export', filterInput)
            .then((response) => {
                setIsLoading(false);
                setOpenAlert(true);
                if (response?.data?.s === 0) {
                    setAlertMesaage(response?.data?.msg);
                    setSeverity('error')
                } else {
                    navigate('/download');
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
                setOpenAlert(true);
                setAlertMesaage(error?.response?.data?.msg);
                setSeverity('error')
            });
    };

    useEffect(() => {
        onLoadApi();
    }, []);

    // You can provide any values or functions you want to make available to consumers here
    const contextValue = {
        setAlertMesaage,
        setSeverity,
        setOpenAlert,
        openSmodal,
        formTemp,
        setFormTemp,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        fDateRange,
        setFDateRange,
        filterInput,
        setFilterInput,
        recordingurl,
        onExport,
        severity,
        alertMesaage,
        openAlert,
        onLoadApi,
        isLoading,
        firstLoading,
        viewData,
        allRecordings,
        allData,
        handleOpenModal,
        handleCloseModal,
        setAddModal,
        addModal,
        setAllData,
        setAllRecordings,
        setViewData,
        setFirstLoading,
        setIsLoading,
    };

    // Render the provider with children
    return <IVRContext.Provider value={contextValue}>{children}</IVRContext.Provider>;
}
