import React from 'react';
import { Container, Box, Typography, Link } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <Container maxWidth="lg" className="footer-container">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          py={3}
        >
          <Typography variant="body1" color="textSecondary" sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <span style={{ marginRight: '5px' }}>
              If you need IT services like App, Website, Software, WhatsApp tool, Digital Marketing, etc., contact <strong>GAINT IT Services </strong> on
            </span>
            <Link
              href="https://wa.me/9717537355"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Contact us on WhatsApp"
            >
              <WhatsAppIcon
                color="success"
                fontSize="large"
                sx={{ cursor: 'pointer', transition: 'transform 0.2s', '&:hover': { transform: 'scale(1.2)' } }}
              />
            </Link>
          </Typography>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
