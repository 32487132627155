import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utils/api';
import { CommonContext } from './CommonContext';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';

export const InvoiceContext = createContext();

export function InvoiceContextProvider({ children }) {
    const { user } = useContext(CommonContext);
    const navigate = useNavigate();
    const [addModal, setAddModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [allData, setAllData] = useState([]);
    const [allInvoice, setAllInvoice] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [viewData, setViewData] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMesaage, setAlertMesaage] = useState('');
    const [severity, setSeverity] = useState('');
    const [alertModal, setAlertModal] = useState(false);
    const [uploadDataRes, setUploadDataRes] = useState();
    const [selected, setSelected] = useState([]);

    const defaultStartDate = moment().startOf('month');
    const defaultEndDate = moment().endOf('month');
    const defaultDateRange = [defaultStartDate.toDate(), defaultEndDate.toDate()];
    const [fStartDate, setFStartDate] = useState(defaultStartDate);
    const [fEndDate, setFEndDate] = useState(defaultEndDate);
    const [fDateRange, setFDateRange] = useState(defaultDateRange);
    const [genDateRange, setGenDateRange] = useState(null);
    const [filterInput, setfilterInput] = useState({});
    const [filterType, setFilterType] = useState('invoice no');
    const [searchValue, setSearchValue] = useState('');
    const [selectedCourierIds, setSelectedCourierIds] = useState([]);
    const [selectedMerchantIds, setSelectedMerchantIds] = useState([]);
    const [userGst, setUserGst] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [invoiceDate, setInvoiceDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [statusModal, setStatusModal] = useState(false);
    const [formDataTemp, setFormDataTemp] = useState({
        merchant_id: [],
        user_gst: '',
        invoice_date: null,
        due_date: null,
        start_date: null,
        end_date: null,
        invoice_no: '',
        cgst_per: '',
        sgst_per: '',
        igst_per: '',
        sub_total: '',
        invoice_excel: null
    });

    const openStatusModal = () => {
        setStatusModal(true);
    };
    const closeStatusModal = () => {
        setStatusModal(false);
    };

    const handleAlertModalModal = () => {
        setAlertModal(true);
    };
    const handleCloseAlertModalModal = () => {
        setAlertModal(false);
        onLoadApi();
    };
    const onCloseAlert = () => {
        setOpenAlert(false);
        setAlertMesaage('');
        setSeverity('')
    };

    const handleAddModal = () => {
        setOpenAlert(false);
        setAlertMesaage('');
        setSeverity('');
        setAddModal(true);
        let postReq = {}
        if (user?.user_type === "Merchant") {
            postReq.merchant_id = user?.id
        } else {
            postReq.admin_id = user?.id
        }
        setFirstLoading(false);
        commonApi.post('/merchant-invoice/user-gst', postReq)
            .then((response) => {
                setFirstLoading(true);
                if (response?.data?.data) {
                    setUserGst(response?.data?.data);
                }
            })
            .catch((error) => {
                setFirstLoading(true);
                console.error('Error fetching data:', error);
            });
    };
    const handleViewModal = () => {
        setViewModal(true);
    };

    const closVieweModal = () => {
        setViewData('');
        setViewModal(false);
    };
    const handleCloseModal = () => {
        if (severity !== 'success') {
            setOpenAlert(false);
            setAlertMesaage('');
            setSeverity('')
        }
        setViewData('');
        setIsEdit(false);
        setAddModal(false);
        setFormDataTemp({
            merchant_id: [],
            user_gst: '',
            start_date: startDate ?? '',
            end_date: endDate,
        });
        setStartDate(null);
        setInvoiceDate(null);
        setEndDate(null);
        setDueDate(null);
    };

    useEffect(() => {
        const fInput = {
            searchBy: searchValue ? filterType.toLowerCase().replace(/\s/g, '_').replace(/\./g, '') ?? '' : '',
            searchValue: searchValue ?? '',
            // courier_id: selectedCourierIds ?? [],
            merchant_id: user?.user_type !== 'Merchant' ? selectedMerchantIds : [user?.id],
            start_date: fDateRange ? moment(fDateRange[0]).format('YYYY-MM-DD') : fStartDate ?? '',
            end_date: fDateRange ? moment(fDateRange[1]).format('YYYY-MM-DD') : fEndDate ?? '',
        }

        setfilterInput(fInput)
    }, [searchValue, filterType, fDateRange, selectedCourierIds, selectedMerchantIds]);

    const onLoadApi = () => {
        // let postReq = {};
        let postReq = {
            searchBy: searchValue ? filterType.toLowerCase().replace(/\s/g, '_').replace(/\./g, '') ?? '' : '',
            searchValue: searchValue ?? '',
            // courier_id: selectedCourierIds ?? [],
            merchant_id: user?.user_type !== 'Merchant' ? selectedMerchantIds : [user?.id],
            start_invoice_date: fDateRange ? moment(fDateRange[0]).format('YYYY-MM-DD') : fStartDate ?? '',
            end_invoice_date: fDateRange ? moment(fDateRange[1]).format('YYYY-MM-DD') : fEndDate ?? '',
            end_invoice_gen_date: genDateRange ? moment(genDateRange[0]).format('YYYY-MM-DD') : '',
            start_invoice_gen_date: genDateRange ? moment(genDateRange[1]).format('YYYY-MM-DD') : '',
            // order_status_id: osId !== 99 ? osId : ''
        };
        if (user?.user_type === "Merchant") {
            postReq.merchant_id = user?.id
        } else {
            postReq.admin_id = user?.id
        }
        setfilterInput(postReq);

        setFirstLoading(false);
        commonApi.post('/merchant-invoice/list', postReq)
            .then((response) => {
                setFirstLoading(true);
                if (response?.data?.data) {
                    setAllInvoice(response?.data?.data);
                    setAllData(response?.data?.data);
                }
            })
            .catch((error) => {
                setFirstLoading(true);
                console.error('Error fetching data:', error);
            });
    };
    const onExportInvoice = (id) => {
        if (user?.user_type !== "Merchant") {
            filterInput.admin_id = user?.id
        } else {
            filterInput.merchant_id = user?.id
        }
        let postReq = {}
        if (id) {
            postReq = { invoice_id: id }
        } else {
            postReq = filterInput
        }
        setIsLoading(true);
        commonApi.post('/merchant-invoice/export', postReq)
            .then((response) => {
                setIsLoading(false);
                setOpenAlert(true);
                if (response?.data?.s === 0) {
                    setAlertMesaage(response?.data?.msg);
                    setSeverity('error')
                } else {
                    navigate('/download');
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
                setOpenAlert(true);
                setAlertMesaage(error?.response?.data?.msg);
                setSeverity('error')
            });
    };
    useEffect(() => {
        onLoadApi()
    }, [])
    console.log('selected', selected)
    // You can provide any values or functions you want to make available to consumers here
    const contextValue = {
        statusModal,
        setStatusModal,
        openStatusModal,
        closeStatusModal,
        selected,
        setSelected,
        setDueDate,
        dueDate,
        invoiceDate,
        setInvoiceDate,
        startDate,
        endDate,
        formDataTemp,
        userGst,
        genDateRange,
        viewModal,
        onExportInvoice,
        filterType,
        searchValue,
        selectedCourierIds,
        selectedMerchantIds,
        setSelectedMerchantIds,
        setSelectedCourierIds,
        setSearchValue,
        setFilterType,
        onLoadApi,
        fDateRange,
        filterInput,
        fStartDate,
        fEndDate,
        uploadDataRes,
        alertModal,
        handleCloseAlertModalModal,
        handleAlertModalModal,
        onCloseAlert,
        severity,
        alertMesaage,
        openAlert,
        isEdit,
        onLoadApi,
        viewData,
        isLoading,
        firstLoading,
        allInvoice,
        allData,
        handleAddModal,
        handleCloseModal,
        setAddModal,
        addModal,
        setAllData,
        setAllInvoice,
        setFirstLoading,
        setIsLoading,
        setViewData,
        setIsEdit,
        setSeverity,
        setAlertMesaage,
        setOpenAlert,
        setAlertModal,
        setUploadDataRes,
        setFEndDate,
        setFStartDate,
        setfilterInput,
        setFDateRange,
        setViewModal,
        closVieweModal,
        handleViewModal,
        setGenDateRange,
        setUserGst,
        setFormDataTemp,
        setEndDate,
        setStartDate,
    };

    // Render the provider with children
    return <InvoiceContext.Provider value={contextValue}>{children}</InvoiceContext.Provider>;
}
